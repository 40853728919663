var exports = {};
exports = exports = trim;

function trim(str) {
  return str.replace(/^\s*|\s*$/g, "");
}

exports.left = function (str) {
  return str.replace(/^\s*/, "");
};

exports.right = function (str) {
  return str.replace(/\s*$/, "");
};

export default exports;
export const left = exports.left,
      right = exports.right;